
.carousel-div{
    display: none;
}
.feature-boxes{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px;
}

.box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(33.33% - 10px);
    background-color: rgba(81, 81, 81, 0.5);
    color: #FDFDFD;
    padding: 20px;
    text-align: start;
    box-sizing: border-box;    
    border-radius: 8px;
}


.content{
    width: 80%;
    margin: auto;
}

.box h4{
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: auto;
    color: rgba(155, 210, 167, 1);
}   

.box p{
    font-size: 14px;
}


.heading-para{
    color: white;
    text-align: center;
}

@media (max-width: 599px){
    .heading-para h2{
        font-size: 35px;
    }

    .feature-boxes{
        display: none;
    }

    .carousel-div{
        display: block;
        width: 100%;
        margin: auto;
        background-color: rgba(81, 81, 81, 0.5);
        border-radius: 8px;

    }
    .carousel-div .box{
        width: 100%;
    }
}

@media (min-width: 600px) and (max-width: 899px){
    .heading-para h2{
        font-size: 35px;
    }

    .feature-boxes{
        display: none;

    }

    .carousel-div{
        display: block;
        width: 70%;
        margin: auto;
        background-color: rgba(81, 81, 81, 0.5);
        border-radius: 8px;

    }
    .carousel-div .box{
        width: 100%;
    }
}


