
.cta-content{
    background-color: rgba(81, 81, 81, 0.5);
    padding: 0px 70px 50px 70px;
    border-radius: 8px;
}

@media (max-width: 599px) {
     .cta-content{
        padding: 10px;
     }

     .cta-btn{
        margin-bottom: 20px;
     }
     .hero-section{
        width: 80%;
        margin: auto;
     }

}

@media (min-width: 600px) and (max-width: 899px){

   .cta-content{
      padding: 10px;
   }

   .cta-btn{
      margin-bottom: 20px;
   }
   .hero-section{
      width: 80%;
      margin: auto;
   }
}

