.box-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33.33% - 10px);
    background-color: rgba(81, 81, 81, 0.5);
    color: #FDFDFD;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;    
    border-radius: 8px;
}
.box-content > *:nth-child(3) > p {
    margin: 0;
}

.profile-photo h4{
    margin: 0px;
}

.boxes{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px;
}

/* Mobile - Responsive */

@media (max-width: 599px) {

    .box-content{
        width: 90%;
        margin: auto;
        display: flex;
        margin-bottom: 20px;
        
    }
    .boxes{
        display: block;
        
    }
  }

  /* tablet responsive */

  @media(min-width: 600px) and (max-width: 899px){

    .box-content{
        width: 50%;
        margin: auto;
        display: flex;
        margin-bottom: 20px;
        
    }
    .boxes{
        display: block;
        
    }

  }