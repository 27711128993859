.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: rgba(255, 255, 255, 1);
  padding: 20px;
  text-align: start;
  box-sizing: border-box;
  max-width: 40%;
}

a img{
  width: 140px;
  /* height: 120px; */
  /* height: fit-content; */
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin: auto;
}

.footer-right {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
  max-width: 50%;
  flex-wrap: wrap;
  gap: 50px;
  text-align: left;
  
}

.footer-section h4 {
  color: rgba(255, 255, 255, 0.8);
  
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.social-media-icon {
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.24);
  padding: 10px;
  width: 40px;
  height: 40px;
}

.footer-end {
  color: #ffffff;
}

@media (max-width: 599px) {
  .footer {
    flex-direction: column;
  }
  .footer-left {
    max-width: 100%;
    padding: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer-right {
    max-width: 100%;
    padding: 0px;
  }
  .footer-end {
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .footer {
    flex-direction: column;
  }
  .footer-left {
    max-width: 100%;
    padding: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer-right {
    max-width: 100%;
    padding: 0px;
    align-content: center;
    justify-content: center;
    margin: auto;
  }
  .footer-end {
    font-size: 10px;
  }
}
