body{
    font-family: 'Poppins', sans-serif;
}

.hero-section{
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-content{
    width: 50%;
}

.hero-content p{
    color: white;
}

.heading{
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0px;

}

.button {
    background-color: #269E40; 
    color: white;
    border-radius: 4px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

.button:hover {
    background-color: #4FBF6A; 
}



@media (max-width: 599px){
    .hero-content{
        width: 90%;
    }
}

@media (min-width: 600px) and (max-width: 899px){
    .hero-content{
        width: 80%;
    }
}





