.policyContent{
    color: white;
    display: flex;
    justify-content: start;
    width: 80%;
    margin: auto;
    flex-direction: column;
    text-align: start;
    padding: 0px ;
}

.policyTitle h1{
    color: white; 
    margin: 0px !important;
}

.emailLink{
    text-decoration: none;
    color: rgba(51, 108, 196, 1);

}

.websiteLink{
    text-decoration: none;
    color: rgba(38, 158, 64, 1);
}

a{
    text-decoration: none;
    color: rgba(51, 108, 196, 1);
}

h2{
    margin: 0px !important;
}

/* responsiveness for mobile device */

@media (max-width: 600px){
    .policyTitle{
        width: 80%;
        margin: auto;
    }
}
