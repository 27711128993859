.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn1{
    background-color: #269E40; 
    color: white;
    border-radius: 4px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: bold;
}
  
.btn1:hover {
    background-color: #4FBF6A; 
}

.btn2{
    border: 1px solid white; 
    background-color: transparent; 
    color: white; 
    border-radius: 4px; 
    padding: 10px 20px;
    cursor: pointer;
    display: inline-flex;
    font-weight: bold;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
  
}

.btns{
  display: flex;
  gap: 10px;
  justify-content: center;

}

.sub-heading{
  color: #FDFDFD; 
}

.my-10 {
  margin: 10px 0;
}
.my-20 {
  margin: 20px 0;
}

.highlight{
  background: linear-gradient(90deg, green, rgb(122, 204, 8)); 
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent; 
  background-clip: text; 

}