.df-aic-jcsa {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.project-catalog {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.project-content {
  color: rgba(255, 255, 255, 1);
  width: 80%;
  margin: auto;
  z-index: 10;
}

.project {
  display: flex;
  justify-content: space-around;
  width: calc(33.333% - 10px);
  box-sizing: border-box;
  background-color: rgba(20, 20, 20, 1);
  border-radius: 8px;
  padding: 10px;
}

.project-title {
  display: flex;
  width: 75%;
  flex-direction: column;
  align-items: start;
}

.project-img {
  width: 10%;
}

.project-title div{
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}

.project-title p {
  margin: 0px;
  font-size: 12px;
}

.project-description {
  margin-bottom: 10px;
}

.project-section-main{
  position: relative;
}

.project-section-main > svg{
  position: absolute;
  top: 10px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}


/* Mobile - Responsive */
@media (max-width: 599px) {
  .project-catalog {
    width: 80%;
    margin: auto;
  }

  .project{
    width: 80%;
    gap: 10px;
    text-align: start;
  }

  .project-img {
    width: 10%;

  }
  .project-title {
    width:90%;
    font-size: 12px;
  }
  .project-img img {
    width: 20px;
  }
  .project-section-main > svg{
    display: none;
  }
}


/* tablet responsive */
@media (min-width: 600px) and (max-width: 900px){

  .project{
    width: calc(50% - 10px);
    text-align: start;
    gap: 10px;
  } 
 
}