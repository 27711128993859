p{
    color: rgba(197, 197, 197, 1);
}

.ourPartners-content{
    width: 80%;
    margin: auto;
}

@media (max-width: 600px) {
    .ourPartners-content{
        width: 80%;
        margin: auto;
    }
  }
  