.blogContainer{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* flex: 1 1 calc(33.333% - 20px);  */
    color: #FDFDFD;
    text-align: start;
    box-sizing: border-box;    
   
}


.blogContent{
    display: flex;
    flex-direction: column;
    justify-content: start;
    background-color: rgba(81, 81, 81, 0.5);
    border-radius: 8px;
    padding: 20px;
    width: 49%;
    box-sizing: border-box;
    margin-top: 10px;
    

} 

.boxContainer{
    padding: 20px;
    border-radius: 8px;
    /* height: 100%; */
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-between;
    /* gap: 20px; */
    flex-wrap: wrap;
    
}

.main{
    border-radius: 100px;
    padding: 6px 12px;
    align-items: center;
    font-size: small;
    width: fit-content;
    margin-top: 10px;
    
}

/* .blog{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
} */

