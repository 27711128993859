.service-content{
    width: 80%;
    margin: auto;
    
}


.carbon-dmrv {
    display: flex;           
    justify-content: space-between; 
    align-items: center;     
    padding: 20px;           
}

.content {
    width: 50%;   
    margin: auto;
    box-sizing: border-box;
    text-align: start;
}

.service-img img{
    width: 70%;
}

.service-name{
    font-size: 30px;
    font-weight: lighter;
    color: #9BD2A7;
    margin-top: 14px;
}

.status{
    border-radius: 100px;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    font-size: small;
    gap: 10px;
}

.new-btns{
    justify-content: start;
}

@media (max-width: 599px){
    .service-content{
        width: 90%; 
        
    }

    .carbon-dmrv {
        padding: 0px;           
        flex-direction: column;
    }

    .new-btns{
        flex-direction: column;
        
    }

    .newBtns2{
         display: flex;
         align-items: center;
         justify-content: center;
    }

    .flex-direction-reverse{
        flex-direction: column-reverse;
    }

    .service-img img{
        width: 90%;
        padding: 10px 0px;
    }
}

@media (min-width: 600px) and (max-width: 899px){

    .carbon-dmrv {
        padding: 0px;           
        flex-direction: column;
    }

    .new-btns{
        flex-direction: column;
        
    }

    .newBtns2{
         display: flex;
         align-items: center;
         justify-content: center;
    }

    .flex-direction-reverse{
        flex-direction: column-reverse;
    }

    .service-img img{
        width: 90%;
        padding: 10px 0px;
    }
    
}