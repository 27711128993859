.header-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}

.header-links {
    display: flex;
    gap: 20px; 
}

.header-links a {
    text-decoration: none;
    color: rgba(163, 163, 163, 1);
    font-size: 16px;
} 

body{
    background-color: black;
    margin-top: 20px;
}

@media (max-width : 599px) {
    .header-links{
        display: none;
    }
    .header-content{
        width: 90%;
        margin: auto;
    }
}

@media (min-width : 600px) and (max-width: 899px){
    .header-links{
        display: none;
    }

    .header-content{
        width: 90%;
        margin: auto;
    }
}